<template>
  <div class="page">

    <h3>VIP营销推广记录</h3>

    <div class="data-table-container">

      <div class="tools-bar">
        <el-button-group class="i">
          <!--          <el-button @click="() => onOpenDetail({})" type="primary">新建</el-button>-->
          <el-button @click="load">刷新</el-button>
        </el-button-group>

        <el-button class="i" @click="onSendRewardMany">批量奖励</el-button>


        <el-select class="i" v-model="filter_status" @change="load" placeholder="请选择类型" clearable>
          <el-option label="未发放奖励" value="未发放奖励"/>
          <el-option label="已发放奖励" value="已发放奖励"/>
        </el-select>

        <div class="grow"></div>
        <!--        <el-input v-model="filter_uid" style="width: 300px" placeholder="请输入 uid">-->
        <!--          <el-button slot="append" @click="onSearchUser">搜索用户</el-button>-->
        <!--        </el-input>-->
      </div>

      <el-table class="table"
                v-loading="loading"
                :data="tableData"
                element-loading-text="获取数据中 ..."
                stripe
                @selection-change="rows => selection = rows"
                highlight-current-row>
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column prop="id" width="90" label="订单ID"/>
        <el-table-column prop="from_uid" width="140" label="邀请人">
          <template slot-scope="scope">
            <xid-link :xid="'user-' + scope.row.from_uid " :uid="scope.row.from_uid"/>
          </template>
        </el-table-column>
        <el-table-column label="订单用户" width="140" prop="user.realname">
          <template slot-scope="scope">
            {{ scope.row.user.realname}} 【{{ scope.row.user.id }}】
          </template>
        </el-table-column>
        <el-table-column label="订单状态" width="90" prop="status"/>
        <el-table-column label="支付金额" width="90">
          <template slot-scope="scope">
            ￥{{ scope.row.fee_paid}}
          </template>
        </el-table-column>

        <el-table-column label="奖励时间" v-if="filter_status === '已发放奖励'"
                         width="160" prop="datetime_rewarded"/>
        <el-table-column label="订单时间" v-else
                         width="160" prop="datetime_created"/>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button-group>
              <!--              <el-button size="small" @click="send_order_invitation_reward(scope.row)">奖励</el-button>-->
              <el-button size="small" @click="row => $router.push({name: 'order-info', query: {id: scope.row.id}})">订单
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination class="pagination"
                     layout="total, prev, pager, next"
                     @current-change="load"
                     :current-page.sync="page"
                     :page-size="30"
                     :total="total"/>

    </div>

  </div>
</template>

<script>
import XidLink from "@/comps/xid-link";

export default {
  components: {XidLink},
  data() {
    return {
      loading: false,
      dialogVisible: false,
      dialogLoading: false,
      dialogForm: {
        user: {},
      },
      rules: {},
      inputSearch: '',
      typeSelect: '',
      typeOptions: ['类型1', '类型2'],
      tableData: [],
      page: 1,
      total: 1,
      filter_status: '未发放奖励',
      selection: [],
    }
  },
  mounted() {
    this.load()
  },
  watch: {
    filter_uid(v) {
      if (!v) {
        this.page = 1
        this.load()
      }
    }
  },
  methods: {
    load() {
      this.loading = true
      this.$api.request('/order/list_market_orders', {
        page: this.page, filter_status: this.filter_status
      }).then(data => {
        this.tableData = data.contains
        this.page = data.page
        this.total = data.total
        this.loading = false
        window.scrollTo(0, 0)
      })
    },
    onPass(id) {
      this.$api.request('/interact/update_comment', {id, status: '正常'}).then(() => {
        this.$message.success('审核通过！')
        this.load()
      })
    },
    onSearchUser() {
      this.page = 1
      this.load()
    },
    onRemove(id) {
      if (confirm('您确定要删除吗？')) {
        this.$api.request('/interact/remove_comment', {id}).then(data => {
          this.$message.success('删除成功！')
          this.load()
        })
      }
    },
    onSelectionChange(rows) {
    },
    async onSendRewardMany() {
      if (!confirm('您确定要发放奖励吗？')) {
        return
      }
      if (this.selection.length < 1) {
        return this.$message.error('请至少选择一行')
      }
      this.loading = true
      this.$message.info('正在发放')

      for (let i = 0; i < this.selection.length; i++) {
        let row = this.selection[i]
        await this.$api.request('/order/send_order_invitation_reward', {order_id: row.id})
      }
      this.load()
      this.$message.success('发放完成')
    },
    send_order_invitation_reward({id}) {
      this.$api.request('/order/send_order_invitation_reward', {order_id: id}).then(() => {
        this.$message.success('奖励发放成功')
      })
    }
  }
}
</script>
