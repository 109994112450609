<template>
  <span>
    <router-link :to="link"> {{ username || content }}</router-link>
  </span>
</template>

<script>
export default {
  name: "xid-link",
  props: ['xid', 'name', 'uid'],
  data() {
    return {
      username: ''
    }
  },
  mounted() {
    if (this.uid) {
      this.$api.request('/user/get', {
        id: this.uid
      }).then(data => {
        this.username = data.realname || data.nickname
      })
    }

  },
  computed: {
    link() {
      let [m, id] = this.xid.split('-')
      return {
        'post': '/post-info?id=',
        'course': '/course-editor?id=',
        'goods': '/shop/goods-editor?id=',
        'user': '/user-info?id=',
        'order': '/order-info?id=',
      }[m] + id
    },
    content() {
      let [m, id] = this.xid.split('-')
      if (this.name) {
        return this.name
      } else {
        return {
          'post': '帖子 ',
          'course': '课程 ',
          'goods': '商品 ',
          'user': '用户 ',
          'order': '订单 ',
        }[m] + this.xid
      }
    }
  },
  methods: {}
}
</script>

<style scoped>


</style>